// Generated by ReScript, PLEASE EDIT WITH CARE

import * as H1 from "../../../../styleguide/components/Heading/H1.res.js";
import * as ID from "../../../../libs/ID.res.js";
import * as Hooks from "../../../../libs/Hooks.res.js";
import * as Table from "../../../../styleguide/components/Table/Table.res.js";
import * as React from "react";
import * as Toggle from "../../../../styleguide/forms/Toggle/Toggle.res.js";
import * as Control from "../../../../styleguide/components/Control/Control.res.js";
import * as Popover from "../../../../styleguide/components/Popover/Popover.res.js";
import * as $$Promise from "../../../../bindings/Promise.res.js";
import * as Spinner from "../../../../styleguide/components/Spinner/Spinner.res.js";
import * as Belt_Map from "rescript/lib/es6/belt_Map.js";
import * as Belt_Set from "rescript/lib/es6/belt_Set.js";
import * as Caml_obj from "rescript/lib/es6/caml_obj.js";
import * as Checkbox from "../../../../styleguide/forms/Checkbox/Checkbox.res.js";
import * as Dropdown from "../../../../styleguide/forms/Dropdown/Dropdown.res.js";
import * as IconMenu from "../../../../styleguide/icons/IconMenu.res.js";
import * as Js_array from "rescript/lib/es6/js_array.js";
import * as TableRow from "../../../../styleguide/components/Table/TableRow.res.js";
import * as Container from "../../../../styleguide/components/Container/Container.res.js";
import * as TableBody from "../../../../styleguide/components/Table/TableBody.res.js";
import * as TableCell from "../../../../styleguide/components/Table/TableCell.res.js";
import * as Belt_Array from "rescript/lib/es6/belt_Array.js";
import * as Pagination from "../../../../styleguide/components/Pagination/Pagination.res.js";
import * as Api_Country from "../../../../api/countries/Api_Country.res.js";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as IconSorting from "../../../../styleguide/icons/IconSorting.res.js";
import * as SearchField from "../../../../styleguide/forms/SearchField/SearchField.res.js";
import * as TableHeader from "../../../../styleguide/components/Table/TableHeader.res.js";
import * as SentryLogger from "../../../../loggers/SentryLogger.res.js";
import * as ReactDebounce from "rescript-debounce-react/src/ReactDebounce.res.js";
import * as Routes_Country from "../../../../routes/common/Routes_Country.res.js";
import * as TableHeaderCell from "../../../../styleguide/components/Table/TableHeaderCell.res.js";
import Format from "date-fns/format";
import * as JsxRuntime from "react/jsx-runtime";
import * as CountriesDashboardNavbar from "./components/CountriesDashboardNavbar.res.js";
import * as CountriesDashboardScss from "./CountriesDashboard.scss";
import * as CountriesDashboardOptions from "./components/CountriesDashboardOptions.res.js";
import * as CountriesDashboardActionBar from "./components/CountriesDashboardActionBar.res.js";

var css = CountriesDashboardScss;

var initialState_sortBy = {
  NAME: "Name",
  VAL: "Asc"
};

var initialState_selection = ID.$$Set.make();

var initialState_entityUpdates = ID.$$Map.make();

var initialState = {
  status: "FetchingCountries",
  currentPage: 1,
  totalPages: 1,
  sortBy: initialState_sortBy,
  search: "",
  selection: initialState_selection,
  entityUpdates: initialState_entityUpdates,
  batchUpdate: undefined,
  batchUpdateError: false
};

function CountriesDashboard$default(props) {
  var container = React.useRef(null);
  var searchCountries = ReactDebounce.useDebounced(300, (function (param) {
          param.dispatch({
                TAG: "PerformSearch",
                _0: param.state.search
              });
        }));
  var match = Hooks.useReducer(initialState, (function (state, action) {
          if (typeof action !== "object") {
            switch (action) {
              case "FetchCountries" :
                  return {
                          TAG: "SideEffects",
                          _0: (function (param) {
                              var dispatch = param.dispatch;
                              var state = param.state;
                              var match = state.search;
                              var tmp = match === "" ? undefined : state.search;
                              $$Promise.wait(Api_Country.fetchCountriesDashboard(state.currentPage, state.sortBy, tmp), (function (x) {
                                      if (x.TAG === "Ok") {
                                        return dispatch({
                                                    TAG: "SucceedCountriesFetch",
                                                    _0: x._0
                                                  });
                                      }
                                      SentryLogger.error1({
                                            rootModule: "CountriesDashboard",
                                            subModulePath: /* [] */0,
                                            value: "default",
                                            fullPath: "CountriesDashboard.default"
                                          }, "CountriesDashboard::FetchCountries::Error", [
                                            "Error",
                                            x._0
                                          ]);
                                      dispatch("FailCountriesFetch");
                                    }));
                            })
                        };
              case "FailCountriesFetch" :
                  return {
                          TAG: "Update",
                          _0: {
                            status: "FailedCountriesFetch",
                            currentPage: state.currentPage,
                            totalPages: state.totalPages,
                            sortBy: state.sortBy,
                            search: state.search,
                            selection: state.selection,
                            entityUpdates: state.entityUpdates,
                            batchUpdate: state.batchUpdate,
                            batchUpdateError: state.batchUpdateError
                          }
                        };
              case "ToggleSelectionOfAllItems" :
                  var match = state.status;
                  var match$1 = state.batchUpdate;
                  if (typeof match !== "object") {
                    return "NoUpdate";
                  }
                  if (match$1 !== undefined) {
                    return "NoUpdate";
                  }
                  var countries = match._0;
                  return {
                          TAG: "Update",
                          _0: {
                            status: state.status,
                            currentPage: state.currentPage,
                            totalPages: state.totalPages,
                            sortBy: state.sortBy,
                            search: state.search,
                            selection: countries.length !== Belt_Set.size(state.selection) ? Belt_Set.fromArray(Belt_Array.map(countries, (function (country) {
                                          return country.id;
                                        })), ID.Comparable) : ID.$$Set.make(),
                            entityUpdates: state.entityUpdates,
                            batchUpdate: state.batchUpdate,
                            batchUpdateError: false
                          }
                        };
              case "DeselectAll" :
                  var match$2 = state.status;
                  var match$3 = state.batchUpdate;
                  if (typeof match$2 !== "object" || match$3 !== undefined) {
                    return "NoUpdate";
                  } else {
                    return {
                            TAG: "Update",
                            _0: {
                              status: state.status,
                              currentPage: state.currentPage,
                              totalPages: state.totalPages,
                              sortBy: state.sortBy,
                              search: state.search,
                              selection: ID.$$Set.make(),
                              entityUpdates: state.entityUpdates,
                              batchUpdate: state.batchUpdate,
                              batchUpdateError: false
                            }
                          };
                  }
              case "ActivateInBatch" :
                  var countries$1 = state.status;
                  if (typeof countries$1 !== "object") {
                    return "NoUpdate";
                  }
                  var countries$2 = countries$1._0;
                  return {
                          TAG: "UpdateWithSideEffects",
                          _0: {
                            status: state.status,
                            currentPage: state.currentPage,
                            totalPages: state.totalPages,
                            sortBy: state.sortBy,
                            search: state.search,
                            selection: state.selection,
                            entityUpdates: state.entityUpdates,
                            batchUpdate: "Activating",
                            batchUpdateError: state.batchUpdateError
                          },
                          _1: (function (param) {
                              var dispatch = param.dispatch;
                              var countryIds = Belt_Array.keep(Belt_Set.toArray(param.state.selection), (function (id) {
                                      var match = (function (__x) {
                                            return Js_array.find((function (country) {
                                                          return Caml_obj.equal(id, country.id);
                                                        }), __x);
                                          })(countries$2);
                                      if (match !== undefined && !match.active) {
                                        return true;
                                      } else {
                                        return false;
                                      }
                                    }));
                              $$Promise.wait(Api_Country.updateActiveInBatch({
                                        countryIds: countryIds,
                                        active: true
                                      }), (function (x) {
                                      if (x.TAG === "Ok") {
                                        return dispatch({
                                                    TAG: "SucceedActivateInBatch",
                                                    _0: countryIds
                                                  });
                                      }
                                      SentryLogger.error1({
                                            rootModule: "CountriesDashboard",
                                            subModulePath: /* [] */0,
                                            value: "default",
                                            fullPath: "CountriesDashboard.default"
                                          }, "CountriesDashboard" + "::ActivateInBatch::Error", [
                                            "Error",
                                            x._0
                                          ]);
                                      dispatch("FailInBatchAction");
                                    }));
                            })
                        };
              case "DeactivateInBatch" :
                  var countries$3 = state.status;
                  if (typeof countries$3 !== "object") {
                    return "NoUpdate";
                  }
                  var countries$4 = countries$3._0;
                  return {
                          TAG: "UpdateWithSideEffects",
                          _0: {
                            status: state.status,
                            currentPage: state.currentPage,
                            totalPages: state.totalPages,
                            sortBy: state.sortBy,
                            search: state.search,
                            selection: state.selection,
                            entityUpdates: state.entityUpdates,
                            batchUpdate: "Deactivating",
                            batchUpdateError: state.batchUpdateError
                          },
                          _1: (function (param) {
                              var dispatch = param.dispatch;
                              var countryIds = Belt_Array.keep(Belt_Set.toArray(param.state.selection), (function (id) {
                                      var match = (function (__x) {
                                            return Js_array.find((function (country) {
                                                          return Caml_obj.equal(id, country.id);
                                                        }), __x);
                                          })(countries$4);
                                      if (match !== undefined && match.active) {
                                        return true;
                                      } else {
                                        return false;
                                      }
                                    }));
                              $$Promise.wait(Api_Country.updateActiveInBatch({
                                        countryIds: countryIds,
                                        active: false
                                      }), (function (x) {
                                      if (x.TAG === "Ok") {
                                        return dispatch({
                                                    TAG: "SucceedDeactivateInBatch",
                                                    _0: countryIds
                                                  });
                                      }
                                      SentryLogger.error1({
                                            rootModule: "CountriesDashboard",
                                            subModulePath: /* [] */0,
                                            value: "default",
                                            fullPath: "CountriesDashboard.default"
                                          }, "CountriesDashboard" + "::DeactivateInBatch::Error", [
                                            "Error",
                                            x._0
                                          ]);
                                      dispatch("FailInBatchAction");
                                    }));
                            })
                        };
              case "FailInBatchAction" :
                  return {
                          TAG: "Update",
                          _0: {
                            status: state.status,
                            currentPage: state.currentPage,
                            totalPages: state.totalPages,
                            sortBy: state.sortBy,
                            search: state.search,
                            selection: state.selection,
                            entityUpdates: state.entityUpdates,
                            batchUpdate: undefined,
                            batchUpdateError: true
                          }
                        };
              
            }
          } else {
            switch (action.TAG) {
              case "SucceedCountriesFetch" :
                  var res = action._0;
                  return {
                          TAG: "UpdateWithSideEffects",
                          _0: {
                            status: {
                              TAG: "Ready",
                              _0: res.countries
                            },
                            currentPage: res.currentPage,
                            totalPages: res.totalPages,
                            sortBy: state.sortBy,
                            search: state.search,
                            selection: state.selection,
                            entityUpdates: state.entityUpdates,
                            batchUpdate: state.batchUpdate,
                            batchUpdateError: state.batchUpdateError
                          },
                          _1: (function (param) {
                              Belt_Option.map(Caml_option.nullable_to_opt(container.current), (function (prim) {
                                      return prim.scrollTop;
                                    }));
                            })
                        };
              case "UpdatePage" :
                  var nextPage = action._0;
                  var match$4 = state.status;
                  if (typeof match$4 !== "object" && match$4 === "FetchingCountries") {
                    return "NoUpdate";
                  }
                  if (state.currentPage !== nextPage) {
                    return {
                            TAG: "UpdateWithSideEffects",
                            _0: {
                              status: "FetchingCountries",
                              currentPage: nextPage,
                              totalPages: state.totalPages,
                              sortBy: state.sortBy,
                              search: state.search,
                              selection: ID.$$Set.make(),
                              entityUpdates: state.entityUpdates,
                              batchUpdate: state.batchUpdate,
                              batchUpdateError: false
                            },
                            _1: (function (param) {
                                param.dispatch("FetchCountries");
                              })
                          };
                  } else {
                    return "NoUpdate";
                  }
              case "SortBy" :
                  var sort = action._0;
                  var match$5 = state.status;
                  if (typeof match$5 !== "object" && match$5 === "FetchingCountries") {
                    return "NoUpdate";
                  }
                  var tmp;
                  if (sort === "TotalCities") {
                    var match$6 = state.sortBy;
                    tmp = typeof match$6 === "object" && match$6.NAME === "TotalCities" && match$6.VAL === "Desc" ? ({
                          NAME: "TotalCities",
                          VAL: "Asc"
                        }) : ({
                          NAME: "TotalCities",
                          VAL: "Desc"
                        });
                  } else if (sort === "UpdatedAt") {
                    var match$7 = state.sortBy;
                    tmp = typeof match$7 === "object" && match$7.NAME === "UpdatedAt" && match$7.VAL === "Desc" ? ({
                          NAME: "UpdatedAt",
                          VAL: "Asc"
                        }) : ({
                          NAME: "UpdatedAt",
                          VAL: "Desc"
                        });
                  } else if (sort === "TotalStates") {
                    var match$8 = state.sortBy;
                    tmp = typeof match$8 === "object" && match$8.NAME === "TotalStates" && match$8.VAL === "Desc" ? ({
                          NAME: "TotalStates",
                          VAL: "Asc"
                        }) : ({
                          NAME: "TotalStates",
                          VAL: "Desc"
                        });
                  } else if (sort === "Name") {
                    var match$9 = state.sortBy;
                    tmp = typeof match$9 === "object" && match$9.NAME === "Name" && match$9.VAL === "Desc" ? ({
                          NAME: "Name",
                          VAL: "Asc"
                        }) : ({
                          NAME: "Name",
                          VAL: "Desc"
                        });
                  } else if (sort === "TotalLocations") {
                    var match$10 = state.sortBy;
                    tmp = typeof match$10 === "object" && match$10.NAME === "TotalLocations" && match$10.VAL === "Desc" ? ({
                          NAME: "TotalLocations",
                          VAL: "Asc"
                        }) : ({
                          NAME: "TotalLocations",
                          VAL: "Desc"
                        });
                  } else {
                    var match$11 = state.sortBy;
                    tmp = typeof match$11 === "object" && match$11.NAME === "Active" && match$11.VAL === "Desc" ? ({
                          NAME: "Active",
                          VAL: "Asc"
                        }) : ({
                          NAME: "Active",
                          VAL: "Desc"
                        });
                  }
                  return {
                          TAG: "UpdateWithSideEffects",
                          _0: {
                            status: "FetchingCountries",
                            currentPage: state.currentPage,
                            totalPages: state.totalPages,
                            sortBy: tmp,
                            search: state.search,
                            selection: ID.$$Set.make(),
                            entityUpdates: state.entityUpdates,
                            batchUpdate: state.batchUpdate,
                            batchUpdateError: false
                          },
                          _1: (function (param) {
                              param.dispatch("FetchCountries");
                            })
                        };
              case "UpdateSearchInput" :
                  return {
                          TAG: "UpdateWithSideEffects",
                          _0: {
                            status: state.status,
                            currentPage: state.currentPage,
                            totalPages: state.totalPages,
                            sortBy: state.sortBy,
                            search: action._0,
                            selection: state.selection,
                            entityUpdates: state.entityUpdates,
                            batchUpdate: state.batchUpdate,
                            batchUpdateError: state.batchUpdateError
                          },
                          _1: searchCountries
                        };
              case "PerformSearch" :
                  var match$12 = state.status;
                  if (typeof match$12 !== "object" && match$12 === "FetchingCountries") {
                    return "NoUpdate";
                  }
                  if (action._0 !== state.search) {
                    return "NoUpdate";
                  } else {
                    return {
                            TAG: "UpdateWithSideEffects",
                            _0: {
                              status: "FetchingCountries",
                              currentPage: state.currentPage,
                              totalPages: state.totalPages,
                              sortBy: state.sortBy,
                              search: state.search,
                              selection: ID.$$Set.make(),
                              entityUpdates: state.entityUpdates,
                              batchUpdate: state.batchUpdate,
                              batchUpdateError: false
                            },
                            _1: (function (param) {
                                param.dispatch("FetchCountries");
                              })
                          };
                  }
              case "ToggleSelectionOfItem" :
                  var id = action._0;
                  var match$13 = state.batchUpdate;
                  if (match$13 !== undefined) {
                    return "NoUpdate";
                  } else {
                    return {
                            TAG: "Update",
                            _0: {
                              status: state.status,
                              currentPage: state.currentPage,
                              totalPages: state.totalPages,
                              sortBy: state.sortBy,
                              search: state.search,
                              selection: Belt_Set.has(state.selection, id) ? Belt_Set.remove(state.selection, id) : Belt_Set.add(state.selection, id),
                              entityUpdates: state.entityUpdates,
                              batchUpdate: state.batchUpdate,
                              batchUpdateError: false
                            }
                          };
                  }
              case "UpdateActive" :
                  var action$1 = action._1;
                  var countryId = action._0;
                  var match$14 = state.status;
                  var match$15 = state.batchUpdate;
                  var match$16 = Belt_Map.get(state.entityUpdates, countryId);
                  if (typeof match$14 !== "object") {
                    match$14 === "FetchingCountries";
                  } else if (match$15 === undefined && match$16 === undefined) {
                    return {
                            TAG: "UpdateWithSideEffects",
                            _0: {
                              status: {
                                TAG: "Ready",
                                _0: Belt_Array.map(match$14._0, (function (x) {
                                        if (!Caml_obj.equal(x.id, countryId)) {
                                          return x;
                                        }
                                        var tmp;
                                        tmp = action$1 === "Activate" ? true : false;
                                        return {
                                                id: x.id,
                                                name: x.name,
                                                slug: x.slug,
                                                code: x.code,
                                                active: tmp,
                                                metaTitle: x.metaTitle,
                                                metaDescription: x.metaDescription,
                                                altNames: x.altNames,
                                                description: x.description,
                                                locationsCount: x.locationsCount,
                                                locationsCountActive: x.locationsCountActive,
                                                citiesCount: x.citiesCount,
                                                citiesCountActive: x.citiesCountActive,
                                                statesCount: x.statesCount,
                                                statesCountActive: x.statesCountActive,
                                                cityState: x.cityState,
                                                administrativeDivision: x.administrativeDivision,
                                                createdAt: x.createdAt,
                                                updatedAt: x.updatedAt
                                              };
                                      }))
                              },
                              currentPage: state.currentPage,
                              totalPages: state.totalPages,
                              sortBy: state.sortBy,
                              search: state.search,
                              selection: state.selection,
                              entityUpdates: Belt_Map.set(state.entityUpdates, countryId, {
                                    TAG: "ActiveUpdate",
                                    _0: action$1
                                  }),
                              batchUpdate: state.batchUpdate,
                              batchUpdateError: state.batchUpdateError
                            },
                            _1: (function (param) {
                                var dispatch = param.dispatch;
                                if (action$1 === "Activate") {
                                  return $$Promise.wait(Api_Country.updateActive(countryId, "Active"), (function (x) {
                                                if (x.TAG === "Ok") {
                                                  return dispatch({
                                                              TAG: "SucceedActiveUpdate",
                                                              _0: countryId
                                                            });
                                                }
                                                SentryLogger.error1({
                                                      rootModule: "CountriesDashboard",
                                                      subModulePath: /* [] */0,
                                                      value: "default",
                                                      fullPath: "CountriesDashboard.default"
                                                    }, "CountriesDashboard" + "::UpdateStatus::Activate::Error", [
                                                      "Error",
                                                      x._0
                                                    ]);
                                                dispatch({
                                                      TAG: "FailActiveUpdate",
                                                      _0: countryId
                                                    });
                                              }));
                                } else {
                                  return $$Promise.wait(Api_Country.updateActive(countryId, "Inactive"), (function (x) {
                                                if (x.TAG === "Ok") {
                                                  return dispatch({
                                                              TAG: "SucceedActiveUpdate",
                                                              _0: countryId
                                                            });
                                                }
                                                SentryLogger.error1({
                                                      rootModule: "CountriesDashboard",
                                                      subModulePath: /* [] */0,
                                                      value: "default",
                                                      fullPath: "CountriesDashboard.default"
                                                    }, "CountriesDashboard" + "::UpdateStatus::Deactivate::Error", [
                                                      "Error",
                                                      x._0
                                                    ]);
                                                dispatch({
                                                      TAG: "FailActiveUpdate",
                                                      _0: countryId
                                                    });
                                              }));
                                }
                              })
                          };
                  }
                  return "NoUpdate";
              case "SucceedActiveUpdate" :
                  return {
                          TAG: "Update",
                          _0: {
                            status: state.status,
                            currentPage: state.currentPage,
                            totalPages: state.totalPages,
                            sortBy: state.sortBy,
                            search: state.search,
                            selection: state.selection,
                            entityUpdates: Belt_Map.remove(state.entityUpdates, action._0),
                            batchUpdate: state.batchUpdate,
                            batchUpdateError: state.batchUpdateError
                          }
                        };
              case "FailActiveUpdate" :
                  var countryId$1 = action._0;
                  var match$17 = state.status;
                  var match$18 = Belt_Map.get(state.entityUpdates, countryId$1);
                  if (typeof match$17 !== "object") {
                    return "NoUpdate";
                  }
                  if (match$18 === undefined) {
                    return "NoUpdate";
                  }
                  var action$2 = match$18._0;
                  return {
                          TAG: "Update",
                          _0: {
                            status: {
                              TAG: "Ready",
                              _0: Belt_Array.map(match$17._0, (function (x) {
                                      if (!Caml_obj.equal(x.id, countryId$1)) {
                                        return x;
                                      }
                                      var tmp;
                                      tmp = action$2 === "Activate" ? true : false;
                                      return {
                                              id: x.id,
                                              name: x.name,
                                              slug: x.slug,
                                              code: x.code,
                                              active: tmp,
                                              metaTitle: x.metaTitle,
                                              metaDescription: x.metaDescription,
                                              altNames: x.altNames,
                                              description: x.description,
                                              locationsCount: x.locationsCount,
                                              locationsCountActive: x.locationsCountActive,
                                              citiesCount: x.citiesCount,
                                              citiesCountActive: x.citiesCountActive,
                                              statesCount: x.statesCount,
                                              statesCountActive: x.statesCountActive,
                                              cityState: x.cityState,
                                              administrativeDivision: x.administrativeDivision,
                                              createdAt: x.createdAt,
                                              updatedAt: x.updatedAt
                                            };
                                    }))
                            },
                            currentPage: state.currentPage,
                            totalPages: state.totalPages,
                            sortBy: state.sortBy,
                            search: state.search,
                            selection: state.selection,
                            entityUpdates: Belt_Map.remove(state.entityUpdates, countryId$1),
                            batchUpdate: state.batchUpdate,
                            batchUpdateError: state.batchUpdateError
                          }
                        };
              case "SucceedActivateInBatch" :
                  var ids = action._0;
                  var countries$5 = state.status;
                  if (typeof countries$5 !== "object") {
                    return "NoUpdate";
                  } else {
                    return {
                            TAG: "Update",
                            _0: {
                              status: {
                                TAG: "Ready",
                                _0: Belt_Array.map(countries$5._0, (function (x) {
                                        var id = x.id;
                                        if ((function (__x) {
                                                return Js_array.includes(id, __x);
                                              })(ids)) {
                                          return {
                                                  id: x.id,
                                                  name: x.name,
                                                  slug: x.slug,
                                                  code: x.code,
                                                  active: true,
                                                  metaTitle: x.metaTitle,
                                                  metaDescription: x.metaDescription,
                                                  altNames: x.altNames,
                                                  description: x.description,
                                                  locationsCount: x.locationsCount,
                                                  locationsCountActive: x.locationsCountActive,
                                                  citiesCount: x.citiesCount,
                                                  citiesCountActive: x.citiesCountActive,
                                                  statesCount: x.statesCount,
                                                  statesCountActive: x.statesCountActive,
                                                  cityState: x.cityState,
                                                  administrativeDivision: x.administrativeDivision,
                                                  createdAt: x.createdAt,
                                                  updatedAt: x.updatedAt
                                                };
                                        } else {
                                          return x;
                                        }
                                      }))
                              },
                              currentPage: state.currentPage,
                              totalPages: state.totalPages,
                              sortBy: state.sortBy,
                              search: state.search,
                              selection: ID.$$Set.make(),
                              entityUpdates: state.entityUpdates,
                              batchUpdate: undefined,
                              batchUpdateError: false
                            }
                          };
                  }
              case "SucceedDeactivateInBatch" :
                  var ids$1 = action._0;
                  var countries$6 = state.status;
                  if (typeof countries$6 !== "object") {
                    return "NoUpdate";
                  } else {
                    return {
                            TAG: "Update",
                            _0: {
                              status: {
                                TAG: "Ready",
                                _0: Belt_Array.map(countries$6._0, (function (x) {
                                        var id = x.id;
                                        if ((function (__x) {
                                                return Js_array.includes(id, __x);
                                              })(ids$1)) {
                                          return {
                                                  id: x.id,
                                                  name: x.name,
                                                  slug: x.slug,
                                                  code: x.code,
                                                  active: false,
                                                  metaTitle: x.metaTitle,
                                                  metaDescription: x.metaDescription,
                                                  altNames: x.altNames,
                                                  description: x.description,
                                                  locationsCount: x.locationsCount,
                                                  locationsCountActive: x.locationsCountActive,
                                                  citiesCount: x.citiesCount,
                                                  citiesCountActive: x.citiesCountActive,
                                                  statesCount: x.statesCount,
                                                  statesCountActive: x.statesCountActive,
                                                  cityState: x.cityState,
                                                  administrativeDivision: x.administrativeDivision,
                                                  createdAt: x.createdAt,
                                                  updatedAt: x.updatedAt
                                                };
                                        } else {
                                          return x;
                                        }
                                      }))
                              },
                              currentPage: state.currentPage,
                              totalPages: state.totalPages,
                              sortBy: state.sortBy,
                              search: state.search,
                              selection: ID.$$Set.make(),
                              entityUpdates: state.entityUpdates,
                              batchUpdate: undefined,
                              batchUpdateError: false
                            }
                          };
                  }
              
            }
          }
        }));
  var dispatch = match[1];
  var state = match[0];
  React.useEffect((function () {
          dispatch("FetchCountries");
        }), []);
  var match$1 = state.search;
  var tmp = match$1 === "" ? undefined : state.search;
  var countries = state.status;
  var tmp$1;
  if (typeof countries !== "object") {
    tmp$1 = false;
  } else {
    var countries$1 = countries._0;
    tmp$1 = Belt_Set.size(state.selection) === countries$1.length && countries$1.length !== 0;
  }
  var match$2 = state.sortBy;
  var match$3 = state.sortBy;
  var match$4 = state.sortBy;
  var match$5 = state.sortBy;
  var match$6 = state.sortBy;
  var match$7 = state.sortBy;
  var countries$2 = state.status;
  var tmp$2;
  if (typeof countries$2 !== "object") {
    tmp$2 = countries$2 === "FetchingCountries" ? JsxRuntime.jsx(TableRow.make, {
            children: JsxRuntime.jsx(TableCell.make, {
                  colSpan: 7,
                  children: JsxRuntime.jsx("div", {
                        children: JsxRuntime.jsx(Spinner.make, {
                              size: "XL",
                              color: "Teal"
                            }),
                        className: css.spinner
                      })
                })
          }) : JsxRuntime.jsx(TableRow.make, {
            className: css.fetchError,
            children: JsxRuntime.jsx(TableCell.make, {
                  colSpan: 7,
                  children: "Something went wrong during the search"
                })
          });
  } else {
    var countries$3 = countries$2._0;
    tmp$2 = countries$3.length !== 0 ? Belt_Array.mapWithIndex(countries$3, (function (index, country) {
              var active = country.active;
              return JsxRuntime.jsxs(TableRow.make, {
                          children: [
                            JsxRuntime.jsx(TableCell.make, {
                                  children: JsxRuntime.jsx(Checkbox.make, {
                                        id: "country--" + (ID.toString(country.id) + "--selected"),
                                        size: "SM",
                                        checked: Belt_Set.has(state.selection, country.id),
                                        onChange: (function (param) {
                                            dispatch({
                                                  TAG: "ToggleSelectionOfItem",
                                                  _0: country.id
                                                });
                                          })
                                      })
                                }),
                            JsxRuntime.jsx(TableCell.make, {
                                  children: JsxRuntime.jsx("a", {
                                        children: country.name,
                                        className: css.tableLink,
                                        href: Routes_Country.Dashboard.edit(country.id)
                                      })
                                }),
                            JsxRuntime.jsx(TableCell.make, {
                                  children: String(country.locationsCountActive)
                                }),
                            JsxRuntime.jsx(TableCell.make, {
                                  children: String(country.statesCountActive)
                                }),
                            JsxRuntime.jsx(TableCell.make, {
                                  children: String(country.citiesCountActive)
                                }),
                            JsxRuntime.jsx(TableCell.make, {
                                  children: JsxRuntime.jsx(Toggle.make, {
                                        on: active,
                                        size: "MD",
                                        onChange: (function () {
                                            dispatch({
                                                  TAG: "UpdateActive",
                                                  _0: country.id,
                                                  _1: active ? "Deactivate" : "Activate"
                                                });
                                          })
                                      })
                                }),
                            JsxRuntime.jsx(TableCell.make, {
                                  children: country.description === "" ? "False" : "True"
                                }),
                            JsxRuntime.jsx(TableCell.make, {
                                  children: Format(country.updatedAt, "MM/dd/yyyy")
                                }),
                            JsxRuntime.jsx(TableCell.make, {
                                  children: JsxRuntime.jsx("div", {
                                        children: JsxRuntime.jsxs(Popover.make, {
                                              children: [
                                                JsxRuntime.jsx(Popover.Trigger.make, {
                                                      children: JsxRuntime.jsx(Control.make, {
                                                            children: JsxRuntime.jsx(IconMenu.make, {
                                                                  size: "MD",
                                                                  color: "Teal"
                                                                })
                                                          })
                                                    }),
                                                JsxRuntime.jsxs(Dropdown.Body.make, {
                                                      position: {
                                                        TAG: "Below",
                                                        _0: "RightEdge"
                                                      },
                                                      className: css.dropdownBody,
                                                      children: [
                                                        JsxRuntime.jsx(Control.make, {
                                                              className: css.dropdownBodyRow,
                                                              children: JsxRuntime.jsx("a", {
                                                                    children: "Edit",
                                                                    className: css.dropdownBodyLink,
                                                                    href: Routes_Country.Dashboard.edit(country.id)
                                                                  })
                                                            }),
                                                        JsxRuntime.jsx(Control.make, {
                                                              className: css.dropdownBodyRow,
                                                              children: JsxRuntime.jsx("a", {
                                                                    children: "View",
                                                                    className: css.dropdownBodyLink,
                                                                    href: Routes_Country.show(country.slug)
                                                                  })
                                                            })
                                                      ]
                                                    })
                                              ]
                                            }),
                                        className: css.providerMenu
                                      })
                                })
                          ]
                        }, String(index));
            })) : JsxRuntime.jsx(TableRow.make, {
            className: css.fetchError,
            children: JsxRuntime.jsx(TableCell.make, {
                  colSpan: 7,
                  children: "No countries were found."
                })
          });
  }
  var countries$4 = state.status;
  var tmp$3;
  if (typeof countries$4 !== "object") {
    tmp$3 = null;
  } else {
    var countries$5 = countries$4._0;
    tmp$3 = JsxRuntime.jsx(CountriesDashboardActionBar.make, {
          selection: Belt_Array.reduce(Belt_Array.map(Belt_Set.toArray(state.selection), (function (id) {
                      return Belt_Option.map((function (__x) {
                                      return Js_array.find((function (country) {
                                                    return Caml_obj.equal(id, country.id);
                                                  }), __x);
                                    })(countries$5), (function (country) {
                                    return country.active;
                                  }));
                    })), [], (function (acc, active) {
                  if (active !== undefined) {
                    return Belt_Array.concat(acc, [active]);
                  } else {
                    return acc;
                  }
                })),
          error: state.batchUpdateError,
          busy: Belt_Option.isSome(state.batchUpdate),
          deselect: (function () {
              dispatch("DeselectAll");
            }),
          activate: (function (param) {
              dispatch("ActivateInBatch");
            }),
          deactivate: (function (param) {
              dispatch("DeactivateInBatch");
            })
        });
  }
  return JsxRuntime.jsx(JsxRuntime.Fragment, {
              children: Caml_option.some(JsxRuntime.jsxs("div", {
                        children: [
                          JsxRuntime.jsx(CountriesDashboardNavbar.make, {}),
                          JsxRuntime.jsxs(Container.make, {
                                className: css.layout,
                                setRef: Caml_option.some(container),
                                children: [
                                  JsxRuntime.jsx("div", {
                                        children: JsxRuntime.jsx(H1.make, {
                                              className: css.title,
                                              children: "Manage Countries"
                                            }),
                                        className: css.header
                                      }),
                                  JsxRuntime.jsxs("div", {
                                        children: [
                                          JsxRuntime.jsx(SearchField.make, {
                                                id: "locations-search",
                                                value: state.search,
                                                placeholder: "Search by name",
                                                inputClassName: css.searchField,
                                                onChange: (function ($$event) {
                                                    dispatch({
                                                          TAG: "UpdateSearchInput",
                                                          _0: $$event.target.value
                                                        });
                                                  })
                                              }),
                                          JsxRuntime.jsx("div", {
                                                children: JsxRuntime.jsx(CountriesDashboardOptions.make, {
                                                      pageNum: state.currentPage,
                                                      query: tmp,
                                                      sortBy: state.sortBy,
                                                      updateCountries: (function (countries) {
                                                          dispatch({
                                                                TAG: "SucceedCountriesFetch",
                                                                _0: countries
                                                              });
                                                        })
                                                    }),
                                                className: css.headerControls
                                              })
                                        ],
                                        className: css.subHeader
                                      }),
                                  JsxRuntime.jsxs(Table.make, {
                                        className: css.table,
                                        children: [
                                          JsxRuntime.jsx(TableHeader.make, {
                                                children: JsxRuntime.jsxs(TableRow.make, {
                                                      children: [
                                                        JsxRuntime.jsx(TableHeaderCell.make, {
                                                              children: JsxRuntime.jsx(Checkbox.make, {
                                                                    id: "location--all--selected",
                                                                    size: "SM",
                                                                    checked: tmp$1,
                                                                    onChange: (function (param) {
                                                                        dispatch("ToggleSelectionOfAllItems");
                                                                      })
                                                                  })
                                                            }),
                                                        JsxRuntime.jsx(TableHeaderCell.make, {
                                                              children: JsxRuntime.jsx(Control.make, {
                                                                    onClick: (function (param) {
                                                                        dispatch({
                                                                              TAG: "SortBy",
                                                                              _0: "Name"
                                                                            });
                                                                      }),
                                                                    children: JsxRuntime.jsxs("span", {
                                                                          children: [
                                                                            "Country",
                                                                            JsxRuntime.jsx(IconSorting.make, {
                                                                                  size: "XXS",
                                                                                  direction: typeof match$2 === "object" && match$2.NAME === "Name" ? match$2.VAL : undefined
                                                                                })
                                                                          ],
                                                                          className: css.controlCell
                                                                        })
                                                                  })
                                                            }),
                                                        JsxRuntime.jsx(TableHeaderCell.make, {
                                                              children: JsxRuntime.jsx(Control.make, {
                                                                    onClick: (function (param) {
                                                                        dispatch({
                                                                              TAG: "SortBy",
                                                                              _0: "TotalLocations"
                                                                            });
                                                                      }),
                                                                    children: JsxRuntime.jsxs("span", {
                                                                          children: [
                                                                            "Active Locations",
                                                                            JsxRuntime.jsx(IconSorting.make, {
                                                                                  size: "XXS",
                                                                                  direction: typeof match$3 === "object" && match$3.NAME === "TotalLocations" ? match$3.VAL : undefined
                                                                                })
                                                                          ],
                                                                          className: css.controlCell
                                                                        })
                                                                  })
                                                            }),
                                                        JsxRuntime.jsx(TableHeaderCell.make, {
                                                              children: JsxRuntime.jsx(Control.make, {
                                                                    onClick: (function (param) {
                                                                        dispatch({
                                                                              TAG: "SortBy",
                                                                              _0: "TotalStates"
                                                                            });
                                                                      }),
                                                                    children: JsxRuntime.jsxs("span", {
                                                                          children: [
                                                                            "Active States",
                                                                            JsxRuntime.jsx(IconSorting.make, {
                                                                                  size: "XXS",
                                                                                  direction: typeof match$4 === "object" && match$4.NAME === "TotalStates" ? match$4.VAL : undefined
                                                                                })
                                                                          ],
                                                                          className: css.controlCell
                                                                        })
                                                                  })
                                                            }),
                                                        JsxRuntime.jsx(TableHeaderCell.make, {
                                                              children: JsxRuntime.jsx(Control.make, {
                                                                    onClick: (function (param) {
                                                                        dispatch({
                                                                              TAG: "SortBy",
                                                                              _0: "TotalCities"
                                                                            });
                                                                      }),
                                                                    children: JsxRuntime.jsxs("span", {
                                                                          children: [
                                                                            "Active Cities",
                                                                            JsxRuntime.jsx(IconSorting.make, {
                                                                                  size: "XXS",
                                                                                  direction: typeof match$5 === "object" && match$5.NAME === "TotalCities" ? match$5.VAL : undefined
                                                                                })
                                                                          ],
                                                                          className: css.controlCell
                                                                        })
                                                                  })
                                                            }),
                                                        JsxRuntime.jsx(TableHeaderCell.make, {
                                                              children: JsxRuntime.jsx(Control.make, {
                                                                    onClick: (function (param) {
                                                                        dispatch({
                                                                              TAG: "SortBy",
                                                                              _0: "Active"
                                                                            });
                                                                      }),
                                                                    children: JsxRuntime.jsxs("span", {
                                                                          children: [
                                                                            "Active?",
                                                                            JsxRuntime.jsx(IconSorting.make, {
                                                                                  size: "XXS",
                                                                                  direction: typeof match$6 === "object" && match$6.NAME === "Active" ? match$6.VAL : undefined
                                                                                })
                                                                          ],
                                                                          className: css.controlCell
                                                                        })
                                                                  })
                                                            }),
                                                        JsxRuntime.jsx(TableHeaderCell.make, {
                                                              children: "Customized"
                                                            }),
                                                        JsxRuntime.jsx(TableHeaderCell.make, {
                                                              children: JsxRuntime.jsx(Control.make, {
                                                                    onClick: (function (param) {
                                                                        dispatch({
                                                                              TAG: "SortBy",
                                                                              _0: "UpdatedAt"
                                                                            });
                                                                      }),
                                                                    children: JsxRuntime.jsxs("span", {
                                                                          children: [
                                                                            "Last Updated",
                                                                            JsxRuntime.jsx(IconSorting.make, {
                                                                                  size: "XXS",
                                                                                  direction: typeof match$7 === "object" && match$7.NAME === "UpdatedAt" ? match$7.VAL : undefined
                                                                                })
                                                                          ],
                                                                          className: css.controlCell
                                                                        })
                                                                  })
                                                            }),
                                                        JsxRuntime.jsx(TableHeaderCell.make, {
                                                              children: ""
                                                            })
                                                      ]
                                                    })
                                              }),
                                          JsxRuntime.jsx(TableBody.make, {
                                                children: tmp$2
                                              })
                                        ]
                                      }),
                                  JsxRuntime.jsx("div", {
                                        children: JsxRuntime.jsx(Pagination.make, {
                                              currentPage: state.currentPage,
                                              totalPages: state.totalPages,
                                              onPageClick: (function (x) {
                                                  dispatch({
                                                        TAG: "UpdatePage",
                                                        _0: x
                                                      });
                                                })
                                            }),
                                        className: css.pagination
                                      })
                                ]
                              }),
                          tmp$3
                        ]
                      }))
            });
}

var $$default = CountriesDashboard$default;

export {
  css ,
  initialState ,
  $$default as default,
}
/* css Not a pure module */
